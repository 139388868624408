<template>
	<div class="top_search">
		<template v-if="isHelp">
			<div class="modes help">
				<div class="item" :class="mode==1? 'active':''" >帮助中心</div>
			</div>
		</template>
		<template v-else-if="antiFake">
			<div class="modes help">
				<div class="item" :class="mode==1? 'active':''" >防伪</div>
			</div>
		</template>
		<template v-else>
			<div class="modes" :class="is_evaluate ? 'three':''">
				<div class="item" :class="mode==1? 'active':''" @click="chooseMode(1)">授权</div>
				<div class="item" :class="mode==2? 'active':''" @click="chooseMode(2)">查询</div>
			</div>
		</template>
		<div class="search_area">
			<div class="search">
				<input type="text" :placeholder="placeholder?placeholder:''" v-model="keyword" @keyup.enter="toGoodsList()">
				<i class="el-icon-search" @click="toGoodsList()"></i>
			</div>
			<div class="add_new el-icon-circle-plus-outline" @click="toPublishInfo()"></div>
		</div>
		<div class="hot_keywords" ref="hotKeywords" v-if="hot_category.length">
			<div class="item" v-for="(item,index) in hot_category" :class="selectCategory==item.category_id?'active':''" :key="index" @click="toGoodsList(item.category_id)">{{item.category_name}}</div>
		</div>
	</div>
</template>

<script>
	import { hotcategory } from "@/api/resource";
	import { apiDefaultSearchWords } from '@/api/pc'
	import { mapGetters } from "vuex"
	export default {
		name: 'top_search',
		props:{
			antiFake: {
				type:Boolean,
				default:false
			},
			isHelp: {
				type: Boolean,
				default:false
			},
			is_evaluate: {
				type: Boolean,
				default:false
			},
			preselection:{
				type:[String,Number],
				default:''
			},
			searchKeyword:{
				type:String,
				default:''
			},
			diyJump:{
				type:Boolean,
				default:false
			},
			isList:{
				type:Boolean,
				default:false
			},
			selectCategory:{
				type:[Number,String],
				default:''
			}
		},
		computed: {
			...mapGetters(["token"])
		},
		data() {
			return {
				mode:1,
				keyword:'',
				hot_category:[],
				placeholder:''
			}
		},
		created() {
			if(this.preselection) this.mode = this.preselection;
			if(this.searchKeyword) this.keyword = this.searchKeyword;
			this.getDefaultSearchWords();
		},
		mounted() {
			this.getHotcategory();
		},
		methods: {
			getDefaultSearchWords(){
				apiDefaultSearchWords()
				.then(res=>{
					if(res.code>=0){
						this.placeholder = res.data.words;
					}
				})
			},
			getHotcategory(){
				hotcategory({
					goods_type:this.mode==1?'resource':'demand'
				})
				.then(res=>{
					if(res.code>=0){
						//全部的宽 964px   每个热词的固定不变宽度：71（左右内边距+右外边距） 每个字占宽15px
						var area_width = 0;
						var keywords_width = 964;
						var show_index = 0;
						var deal_length = false;
						try{
							 res.data.forEach((item,index)=>{
								area_width += item.category_name.length*15+71;
								if(area_width>=keywords_width){
									show_index = index;
									area_width -= item.category_name.length*15+71;
									throw Error();
								}
							})
						}catch(e){
							deal_length = true;
							this.hot_category =  res.data.slice(0,show_index);
							return;
						}
						if(!deal_length) this.hot_category = res.data;
					}
				})
				.catch(err=>{
					
				})
			},
			toGoodsList(id){
				if(id){
					//更改热词分类
					if(this.isList){
						//是列表
						this.$emit('changeHotCategory',id);
					}else{
						this.$router.pushToTab('/list?category_id='+id+'&mode='+this.mode);
					}
				}else{
					//搜索框搜索
					if(this.isList){
						this.$emit('searchKeywordList',this.keyword);
					}else{
						if(this.keyword){
							this.$router.pushToTab({path:'/list',query:{keyword:this.keyword,mode:this.mode}});
						}else{
							this.$router.pushToTab({path:'/list',query:{mode:this.mode}});
						}
					}
					
				}
			},
			toPublishInfo(){
				if(!this.token){
					this.$message.warning('请先进行登录');
					setTimeout(()=>{
						this.$router.push({path: '/login', query: {redirect: "/cms/publish_info?mode="+this.mode}});
					},800)
					return;
				}
				this.$router.push('/cms/publish_info?mode='+this.mode);
			},
			chooseMode(mode) {
				this.mode = mode;
				this.getHotcategory();
				if(this.diyJump){
					this.$emit('changeMode',mode)
				}else{
					this.$router.push('/?mode='+mode);
				}
			}
		},
	}
</script>

<style lang="scss">
	.top_search{
		box-sizing: border-box;
		padding: 0 118px 0 118px;
		.modes{
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 20px;
			&.three{
				.item{
					margin-right: 10px;
					&:last-child{
						margin-right: 0;
					}
				}
			}
			&.help{
				.item{
					width: 180px;
					height: 53px;
					display: flex;
					align-items: center;
					justify-content: center;
					box-sizing: border-box;
					padding: 0;
					border-radius: 5px !important;
				}
			}
			.item{
				color: #30373D;
				font-size: 19px;
				line-height: 23px;
				padding: 13px 71px;
				font-weight: 600;
				background-color: #fff;
				cursor: pointer;
				&.active{
					background-color: #30373D;
					color: #FEFEFE;
				}
				&:first-child{
					border-radius: 5px 0px 0px 5px;
				}
				&:last-child{
					border-radius: 0px 5px 5px 0px;
				}
			}
		}
		.search_area{
			display: flex;
			align-items: center;
			.search{
				display: flex;
				align-items: center;
				background-color: #FFFFFF;
				padding: 0 18px 0 20px;
				height: 48px;
				margin-right: 20px;
				width: 906px;
				box-sizing: border-box;
				border-radius: 50px;
				input{
					flex: 1;
					margin-right: 10px;
					outline: none;
					border: none;
					font-size: 15px;
					color: #30373D;
					padding: 0;
				}
				input::placeholder{
					color: #999;
					font-family: "PingFang SC";
					font-size: 15px;
				}
				.el-icon-search{
					cursor: pointer;
					color: #30373D;
					font-size: 23px;
					line-height: 1;
				}
			}
			.add_new{
				cursor: pointer;
				color: #30373D;
				font-size: 42px;
			}
		}
		.hot_keywords{
			display: flex;
			align-items: center;
			margin-top: 20px;
			.item{
				white-space: nowrap;
				cursor: pointer;
				background-color: #FFFFFF;
				color: rgba(48, 55, 61, 0.60);
				font-size: 15px;
				line-height: 22px;
				padding: 10px 28px;
				margin-right: 15px;
				border-radius: 5px;
				&:hover,
				&.active{
					background-color: #30373D;
					color: #fff;
				}
			}
		}
	}
</style>