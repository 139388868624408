<template>
	<div class="buy_payment">
		<top-search @changeMode="changeMode"></top-search>
		<div class="category_lists" ref="scrollBox">
			<div class="item" ref="scrollItem" @click="chooseCategory(item.category_id,index,item.attr_class_id)" :class="category_id == item.category_id ? 'active' : ''" v-for="(item,index) in all_category" :key="index">{{item.category_name}}<div :class="category_id == item.category_id ? 'active' : ''" class="line"></div>
			</div>
		</div>
		<div class="choose_area">
			<div class="form_item">
				<div class="tit">知产</div>
				<div class="right">
					<el-select @change="changeCondition('type',$event)" v-model="brand_type" placeholder="授权类型">
					    <el-option
					      v-for="(item,index) in brand_type_list"
					      :key="item.id"
					      :label="item.name"
					      :value="item.id">
					    </el-option>
					</el-select>
				</div>
			</div>
			<div class="form_item">
				<div class="tit">类别</div>
				<div class="right">
					<el-select @change="changeCondition('category',$event)" :multiple="true" v-model="class_names" placeholder="全部" >
					    <el-option
					      v-for="(item,index) in class_list"
					      :key="item.category_id"
					      :label="item.category_name"
					      :value="item.category_id">
					    </el-option>
					</el-select>
				</div>
			</div>
			<div class="form_item">
				<div class="tit">名称</div>
				<div class="right">
					<el-select @change="changeCondition('name',$event)" v-model="goods_name" placeholder="名称">
					    <el-option
					      v-for="(item,index) in goods_names_list"
					      :key="item.goods_name"
					      :label="item.goods_name"
					      :value="item.goods_name">
					    </el-option>
					</el-select>
				</div>
			</div>
			<div class="form_item">
				<div class="tit">注册号</div>
				<div class="right">
					<el-select v-model="reg_no" placeholder="注册号">
					    <el-option
					      v-for="(item,index) in reg_no_list"
					      :key="item.reg_no"
					      :label="item.reg_no"
					      :value="item.reg_no">
					    </el-option>
					</el-select>
				</div>
			</div>
			<div class="form_item">
				<div class="tit">平台</div>
				<div class="right">
					<el-select v-model="platform" class="input_select_type" filterable allow-create default-first-option placeholder="平台">
					    <el-option
					      v-for="(item,index) in platform_list"
					      :key="item"
					      :label="item"
					      :value="item">
					    </el-option>
					</el-select>
				</div>
			</div>
			<div class="form_item">
				<div class="tit">公司</div>
				<div class="right">
					<input class="right_input" placeholder="公司" type="text" v-model="company_name">
				</div>
			</div>
			<div class="form_item">
				<div class="tit">店铺</div>
				<div class="right">
					<el-select v-model="store_type" class="input_select_type" filterable allow-create default-first-option placeholder="店铺">
					    <el-option
					      v-for="(item,index) in store_type_list"
					      :key="item"
					      :label="item"
					      :value="item">
					    </el-option>
					</el-select>
				</div>
			</div>
			<div class="form_item">
				<div class="tit">期限</div>
				<div class="right">
					<el-select @change="changeCondition('limit',$event)" v-model="date_limit" placeholder="期限时长">
					    <el-option
					      v-for="(item,index) in date_limit_list"
					      :key="item.id"
					      :label="item.name"
					      :value="item.id">
					    </el-option>
					</el-select>
					<!-- <el-date-picker
						value-format="yyyy-MM-dd"
						format="yyyy/MM/dd"
						v-model="date_area"
						:picker-options="chooseTimeArea"
						type="daterange"
						range-separator="至"
						start-placeholder="开始日期"
						end-placeholder="结束日期">
					</el-date-picker> -->
				</div>
			</div>
			<div class="actions">
				<div class="action" @click="toAccreditDetail(1,'balance')">余额</div>
				<div class="action" @click="toAccreditDetail(1,'wechat')">微信</div>
				<div class="action" @click="toAccreditDetail(1,'alipay')">支付宝</div>
				<div class="action" @click="toAccreditDetail(2)">预览</div>
			</div>
		</div>
		<el-dialog class="pay_qrcode" :show-close="false" :visible.sync="qrcodeShow" :close-on-click-modal="false">
			<div class="tit">{{qrcode_type=='alipay'?'支付宝':'微信'}}<div class="line"></div></div>
			<img class="qrcode" :src="qrcode_img" />
			<div class="tips">打开{{qrcode_type=='alipay'?'支付宝':'微信'}}支付</div>
			<i class="el-icon-error" @click="closePayQrcode()"></i>
		</el-dialog>
		<servicerMessage ref="servicerMessage" class="kefu" :toUid="toUid"></servicerMessage>
	</div>
</template>

<script>
	import servicerMessage from "@/components/message/servicerMessage"
	import topSearch from '@/components/top_search';
	import detail from './payment.js';
	export default {
		name: 'payment',
		components: {
			topSearch,
			servicerMessage
		},
		mixins: [detail]
	};
</script>

<style lang="scss" scoped>
	@import './payment.scss';
	.iframe-style{
		width: 0;
		height: 0;
		border: none;
	}
</style>
<style lang="scss">
	.pay_qrcode{
		.el-dialog{
			margin-top: 0 !important;
			top: 50%;
			transform: translateY(-50%);
			max-width: 554px;
			border-radius: 10px;
		}
		.el-dialog__header{
			padding:0;
		}
		.el-dialog__body{
			position: relative;
			padding: 76px 134px 86px;
			border-radius: 10px;
			background-color: #fff;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			.tit{
				width: 100%;
				border-bottom: 1px solid rgba(48, 55, 61, 0.10);
				color: #30373D;
				font-size: 19px;
				line-height: 23px;
				display: flex;
				align-items: center;
				flex-direction: column;
				padding-bottom: 25px;
				position: relative;
				.line{
					width: 34px;
					height: 2px;
					background-color: #30373D;
					border-radius: 6px;
					position: absolute;
					bottom: 0;
					transform: translateY(50%);
				}
			}
			.qrcode{
				width: 224px;
				border-radius: 3px;
				display: block;
				margin-top: 30px;
				height: 224px;
			}
			.tips{
				margin-top: 30px;
				color: #999;
				font-size: 13px;
				line-height: 15px;
			}
			.el-icon-error{
				color: rgba(48, 55, 61, 0.50);
				font-size: 18px;
				line-height: 18px;
				position: absolute;
				right: 20px;
				top: 20px;
				cursor: pointer;
			}
		}
	}
	.form_item .el-select{
		.el-select__tags{
			// justify-content: center;
		}
		.el-input__inner{
			&::placeholder{
				color: #30373D !important;
			}
		}
		.el-tag{
			margin-top: 0;
			margin-bottom: 0;
			color: #30373D;
			.el-tag__close{
				color: #fff;
				font-size: 11px;
				background-color: #30373D;
			}
		}
		.el-tag--small{
			height: 21px;
			line-height: 21px;
			border: none;
			background-color: transparent;
		}
	} 
	.el-select-dropdown{
		.el-scrollbar{
			.el-scrollbar__view{
				.el-select-dropdown__item{
					text-align: center;
				}
			}
		}
	}
	.choose_area .el-select .el-input .el-select__caret {
	  transform: rotateZ(0deg);
	  width: 12px;
	  font-size: 12px;
	  line-height: 1;
	}
	.choose_area .el-select .el-input .el-select__caret::before {
	  content: "\e790";
	  position: absolute;
	  width: 100%;
	  height: 100%;
	  top: 50%;
	  left: 50%;
	  color: #30373D !important;
	  transform: translate(-50%, -50%);
	}
	.choose_area{
		.el-date-editor{
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #F7F8FB;
			border: none;
			height: 41px;
			padding: 0;
			.el-range__close-icon{
				line-height: 41px;
				position: absolute;
				right: 33%;
			}
			.el-range-separator{
				width: 15px;
				padding: 0;
				height: 100%;
				line-height: 41px;
				color: #30373D;
			}
			.el-range-input{
				background-color: transparent;
				color: #30373D;
				font-size: 15px;
				line-height: 41px;
				height: 100%;
				width: 88px;
				&::placeholder{
					color: rgba(48, 55, 61, 0.50);
					font-family: "PingFang SC";
				}
			}
			.el-input__icon.el-icon-date{
				display: none;
			}
		}
		.el-select{
			width: 100%;
			background-color: #F7F8FB;
			border-radius: 5px;
			padding: 10px 0;
			.el-input--suffix{
				display: flex;
				align-items: center;
				justify-content: center;
				line-height: 1;
				.el-input__inner{
					width: 28%;
					background-color: transparent;
					border:none;
					padding: 0;
					height: auto;
					text-align: center;
					color: #30373D;
					font-size: 15px;
					line-height: 21px;
					&::placeholder{
						color: rgba(48, 55, 61, 0.50);
						font-family: "PingFang SC";
					}
				}
				.el-input__suffix{
					position: relative;
					right: 0;
					height: auto;
					text-align: left;
					.el-input__suffix-inner{
						line-height: 12px;
						display: block;
					}
				}
			}
		}
	}
	.input_select_type.el-select{
		.el-input__inner{
			&::placeholder{
				color: rgba(48, 55, 61, 0.50) !important;
			}
		}
	}
</style>