import { mapGetters } from "vuex"
import { categoryList } from '@/api/resource.js'
import Config from '@/utils/config';
import Util from '@/utils/util.js'
import { brandTypeList, goodsCategoryList, goodsNameList, goodsLists, AuthLetterCofig, orderCreate, orderPay, orderWechatPay, orderAliPay, base64Qrcode, getPayStatus, dateLimit } from '@/api/accredit.js'
export default {
    data: () => {
        return {
			current_mode: 1,
			current_category:0,
			category_id:'',
			all_category:[],
			toUid:'',
			flag:false,
			brand_type_list:[{id:'',name:'全部'}],
			brand_type:0,
			date_limit_list:[],
			date_limit:'',
			class_list:[],
			class_names:[],
			goods_names_list:[],
			goods_name:'',
			reg_no_list:[],
			reg_no:'',
			platform_list:[],
			platform:'',
			store_type_list:[],
			store_type:'',
			company_name:'',
			date_area:'',
			first:true,
			chooseTimeArea:{
				disabledDate(time){
					let arr = new Date().toLocaleDateString().split('/');
					return time.getTime() < new Date(new Date().toLocaleDateString()).getTime() || time.getTime() > Util.timeTurnTimeStamp(Number(arr[0])+5+'-'+arr[1]+'-'+arr[2])*1000;
				}
			},
			select_goods_info:null,
			flag:false,
			customer_agent_member_id:'',
			qrcodeShow:false,
			qrcode_type:'',
			qrcode_img:'',
			iframe_src:''
		}
    },
    created() {
		this.getCategoryList();
		if(localStorage.getItem('select_accredit')){
			this.select_goods_info =  JSON.parse( localStorage.getItem('select_accredit') );
		}
		if(localStorage.getItem('customer_agent_member_id')){
			this.customer_agent_member_id =  localStorage.getItem('customer_agent_member_id') ;
		}
		this.getAccreditTypeList();
		this.getAuthLetterCofig();
		this.getDateLimit();
    },
    computed: {
        ...mapGetters(["token", "defaultHeadImage", "defaultShopImage", "defaultGoodsImage", "addonIsExit", "locationRegion","member","orderCreateGoodsData"])
    },
	methods: {
		
		getDateLimit(){
			dateLimit()
			.then(res=>{
				if(res.code>=0){
					this.date_limit_list = res.data;
					this.date_limit = this.date_limit_list[0].id;
				}
			})
		},
		closePayQrcode(){
			this.qrcodeShow = false;
		},
		getOrderPayStatus(out_trade_no,order_id){
			getPayStatus({
				out_trade_no:out_trade_no
			})
			.then(res=>{
				if(res.code>=0){
					if(res.data.pay_status){
						this.$message.success('支付成功')
						clearTimeout(this.timer);
						this.qrcodeShow = false;
						this.qrcode_type = '';
						localStorage.removeItem('select_accredit');
						localStorage.removeItem('customer_agent_member_id');
						this.$router.push('/cms/accredit-detail-'+order_id)
					}else{
						this.timer = setTimeout(()=>{
							this.getOrderPayStatus(out_trade_no);
						},1000)
					}
				}else{
					this.$message.error(res.message);
				}
			})
			.catch(err=>{
				this.$message.error(err.message);
			})
		},
		toAccreditDetail(mode,type){
			//mode 1  创建完直接支付  去选择付款方式  余额：直接扣  wx/alipay：余额不够 弹码支付
			//mode 2  创建完预览模板  跳到模板选择
			if(!this.reg_no){
				this.$message.error('请选择购买的注册号');
				return;
			}
			if(!this.platform){
				this.$message.error('请选择平台名称');
				return;
			}
			if(!this.store_type){
				this.$message.error('请选择店铺名称');
				return;
			}
			if(!this.company_name){
				this.$message.error('请填写公司名称');
				return;
			}
			// if(!this.date_area){
			// 	this.$message.error('请选择期限时间');
			// 	return;
			// }
			if(this.flag) return;
			this.flag = true;
			orderCreate({
				reg_no:this.reg_no,
				platform:this.platform,
				store_type:this.store_type,
				company:this.company_name,
				// auth_begin_date:this.date_area[0],
				// auth_end_date:this.date_area[1],
				date_limit:this.date_limit,
				customer_agent_member_id:this.customer_agent_member_id
			})
			.then(res=>{
				if(res.code>=0){
					// res.data.order_id
					var order_id = res.data.order_id;
					switch (mode){
						case 1:
							//申请付款
							// this.qrcodeShow = true;
							switch (type){
								case 'balance':
									orderPay({
										order_id:res.data.order_id
									})
									.then(res=>{
										if(res.code>=0){
											this.$message({
												message:"支付成功",
												type:"success"
											})
											setTimeout(()=>{
												// this.$router.push('/member/index?my_current=12')
												localStorage.removeItem('select_accredit');
												localStorage.removeItem('customer_agent_member_id');
												this.$router.push('/cms/accredit-detail-'+order_id)
											},800)
										}else{
											this.$message.error(res.message);
										}
									})
									.catch(err=>{
										this.$message.error(err.message);
										if( err.error_code == 'ACCOUNT_EMPTY' ){
											setTimeout(()=>{
												this.$router.push('/member/index?isRecharge=1&my_current=9')
											},800)
										}
									})
									break;
								case 'wechat':
									orderWechatPay({
										order_id:res.data.order_id,
										pay_type:'wechatpay'
									})
									.then(res=>{
										if(res.code>=0){
											if(res.data){
												if(res.data.type=='url'){
													
												}else{
													//弹二维码
													this.qrcode_type = 'wechat';
													this.qrcode_img = res.data.qrcode;
													this.qrcodeShow = true;
													//检测是否支付成功
												}
												this.getOrderPayStatus(res.data.out_trade_no,order_id);
											}else{
												this.$message({
													message:"支付成功",
													type:"success"
												})
												setTimeout(()=>{
													localStorage.removeItem('customer_agent_member_id');
													localStorage.removeItem('select_accredit');
													this.$router.push('/cms/accredit-detail-'+order_id)
												},800)
											}
										}else{
											this.$message.error(res.message);
										}
									})
									.catch(err=>{
										this.$message.error(err.message);
									})
									break;
								case 'alipay':
									orderAliPay({
										order_id:res.data.order_id,
										pay_type:'alipay'
									})
									.then(res=>{
										if(res.code>=0){
											if(res.data){
												if(res.data.type=='url'){
													base64Qrcode({
														text:res.data.data
													})
													.then(res=>{
														if(res.code>=0){
															this.qrcode_type = 'alipay';
															this.qrcode_img = res.data;
															this.qrcodeShow = true;
														}
													})
												}
												this.getOrderPayStatus(res.data.out_trade_no,order_id);
											}else{
												this.$message({
													message:"支付成功",
													type:"success"
												})
												setTimeout(()=>{
													localStorage.removeItem('select_accredit');
													localStorage.removeItem('customer_agent_member_id');
													this.$router.push('/cms/accredit-detail-'+order_id)
												},800)
											}
										}else{
											this.$message.error(res.message);
										}
									})
									.catch(err=>{
										this.$message.error(err.message);
									})
									break;
								default:
									break;
							}
							break;
						case 2:
							localStorage.removeItem('select_accredit');
							localStorage.removeItem('customer_agent_member_id');
							this.$router.push('/cms/accredit-detail-'+res.data.order_id);
							break;
						default:
							break;
					}
					this.flag = false;
				}else{
					this.$message.error(res.message);
					this.flag = false;
				}
			})
			.catch(err=>{
				this.$message.error(err.message);
				this.flag = false;
			})
		},
		changeCondition(mode,e){
			switch (mode){
				case 'type':
					this.getGoodsCategoryList();
					break;
				case 'category':
					this.getGoodsNameList();
					break;
				case 'name':
					this.getGoodsList();
					break;
				default:
					break;
			}
		},
		getAuthLetterCofig(){
			AuthLetterCofig()
			.then(res=>{
				if(res.code>=0){
					this.platform_list = res.data.platform;
					this.store_type_list = res.data.store_type;
				}
			})
		},
		getGoodsList(){
			goodsLists({
				brand_type:this.brand_type,
				category_ids:this.class_names.toString(),
				goods_name:this.goods_name=='全部'?'':this.goods_name
			})
			.then(res=>{
				if(res.code>=0){
					this.reg_no_list = [];
					// this.reg_no_list = [{reg_no:'全部'}];
					this.reg_no_list.push(...res.data);
					if(this.first){
						if(this.select_goods_info){
							this.reg_no_list.forEach(item=>{
								if(item.reg_no == this.select_goods_info.reg_no){
									this.reg_no = item.reg_no;
								}
							});
						}else{
							this.reg_no = this.reg_no_list[0].reg_no;
						}
						
					}else{
						//条件更改
						this.reg_no = this.reg_no_list[0].reg_no;
					}
					this.first = false;
				}
			})
		},
		getGoodsNameList(){
			goodsNameList({
				brand_type:this.brand_type,
				category_ids:this.class_names.toString(),
			})
			.then(res=>{
				if(res.code>=0){
					this.goods_names_list = [{goods_name:'全部'}];
					this.goods_names_list.push(...res.data);
					if(this.first){
						if(this.select_goods_info){
							this.goods_names_list.forEach(item=>{
								if(item.goods_name == this.select_goods_info.goods_name){
									this.goods_name = item.goods_name;
								}
							})
						}else{
							this.goods_name = this.goods_names_list[0].goods_name;
						}
						
					}else{
						//条件更改
						this.goods_name = this.goods_names_list[0].goods_name;
					}
					this.getGoodsList();
				}
			})
		},
		getGoodsCategoryList(){
			goodsCategoryList({
				brand_type:this.brand_type
			})
			.then(res=>{
				if(res.code>=0){
					this.class_list = [];
					this.class_names = [];
					// this.class_list = [{category_id:'',category_name:'全部'}];
					this.class_list.push(...res.data);
					
					if(this.first){
						var ids = [];
						this.class_list.forEach(item=>{
							ids.push(item.category_id);
						})
						if(this.select_goods_info){
							this.select_goods_info.category_id.split(',').forEach(item=>{
								if(ids.indexOf( Number(item) )!=-1){
									this.class_names.push(Number(item))
								}
							})
						}else{
							
						}
						
					}
					this.getGoodsNameList();
				}
			})
		},
		getAccreditTypeList(){
			brandTypeList()
			.then(res=>{
				if(res.code>=0){
					this.brand_type_list = [{id:'',name:'全部'}]
					this.brand_type_list.push(...res.data);
					if(this.select_goods_info){
						this.brand_type = this.select_goods_info.brand_type;
					}else{
						this.brand_type = this.brand_type_list[0].id;
					}
					this.getGoodsCategoryList();
				}
			})
		},
		chooseCategory(category_id,index,class_id){
			this.goods_loading = true;
			this.current_category = index;
			this.category_id = category_id;
			let lastSpot = this.$refs.scrollBox.scrollLeft;
			const nextSpace = 50; //每次移动距离
			let scrollItemTimer = setInterval(() => {
				this.$nextTick(() => {
					let offsetWidth = this.$refs.scrollItem[this.current_category].offsetWidth;
					let scrollLeft = this.$refs.scrollItem[this.current_category].offsetLeft;
					const containWidth = this.$refs.scrollBox.offsetWidth;
					if(this.$refs.scrollItem[0].offsetLeft == 0){
						var resultSpot = scrollLeft + offsetWidth / 2 - containWidth / 2;
					}else{
						var resultSpot = scrollLeft + offsetWidth / 2 - containWidth / 2 - this.$refs.scrollItem[0].offsetLeft;
					}
					if (Math.abs(lastSpot - resultSpot) < nextSpace) {
						clearInterval(scrollItemTimer);
					}
					if (resultSpot >= lastSpot) {
						lastSpot = lastSpot + nextSpace;
					} else {
						lastSpot = lastSpot - nextSpace;
					}
					this.$refs.scrollBox.scrollTo(lastSpot, 0);
				});
			}, 15);
		},
		getCategoryList(){
			categoryList({
				goods_type:this.current_mode==1?'resource':'demand'
			})
			.then(res=>{
				if(res.code>=0){
					this.all_category = [{'category_id':'','category_name':'推荐'}]
					this.all_category.push(...this.$util.deepClone(res.data));
					this.category_id = this.all_category[0].category_id;
					this.current_category = 0;
				}
			})
		},
		changeMode(mode) {
		    this.current_mode = mode
		},
	}
}
